@-webkit-keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.1, 1.1, 1);
		transform: scale3d(1.1, 1.1, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.1, 1.1, 1);
		transform: scale3d(1.1, 1.1, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

// WIDGETS
@-webkit-keyframes spin3D {
	from {
		transform: rotate3d(0.5, 0.5, 0.5, 360deg);
	}
	to {
		transform: rotate3d(0deg);
	}
}

@keyframes spin3D {
	from {
		transform: rotate3d(0.5, 0.5, 0.5, 360deg);
	}
	to {
		transform: rotate3d(0deg);
	}
}

@-webkit-keyframes heartbeat {
	20%,
	60% {
		-webkit-transform: (1);
		transform: scale(1);
	}
	0%,
	40%,
	80%,
	100% {
		-webkit-transform: (0.75);
		transform: scale(0.75);
	}
}

@keyframes heartbeat {
	20%,
	60% {
		-webkit-transform: (1);
		transform: scale(1);
	}
	0%,
	40%,
	80%,
	100% {
		-webkit-transform: (0.75);
		transform: scale(0.75);
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes spin3D {
	from {
		transform: rotate3d(0.5, 0.5, 0.5, 360deg);
	}
	to {
		transform: rotate3d(0deg);
	}
}

@keyframes spin3D {
	from {
		transform: rotate3d(0.5, 0.5, 0.5, 360deg);
	}
	to {
		transform: rotate3d(0deg);
	}
}
