@import './variables';

.backButton {
	z-index: 9;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
		0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
	background: #fff !important;
	position: fixed !important;
	bottom: 3vh;
	left: 3vw;
}

.telInput-flag {
	height: 56px;
	cursor: pointer !important;
}

.telInput-selectFlag {
	width: 56px;
	height: 54px;
	padding: 18.5px 14px;
	border: none;
	background: #fff !important;
}

.teInput-formControl {
	color: $grayFont;
	font-size: 16px !important;
	padding-left: 62px !important;
	height: 56px !important;
	border-radius: 4px !important;
	box-shadow: 0 1px 2px #fff inset !important;
	&:focus {
		outline: transparent none 0 !important;
	}
}
.invalid {
	background: #fff !important;
}
.react-tel-input {
	width: 100% !important;

	.selected-flag {
		z-index: 1 !important;
	}

	.form-control.invalid-number {
		@extend .invalid;
	}

	.flag-dropdown.invalid-number {
		@extend .invalid;
	}

	.flag-dropdown {
		@extend .telInput-flag;
		border: 1px solid #e6ebf1 !important;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: none;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
		.open {
			height: 0px;
		}
	}
}

.telInputErrorFull.react-tel-input {
	.flag-dropdown {
		border: 1px solid #f44336 !important;
		.selected-flag {
			&:focus {
				border: 2px #f44336 solid;
			}
		}
	}
}

.telInputFull {
	width: 100% !important;
	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 1px $lightGrayAsistensi solid;
			&:focus {
				border: 2px $lightGrayAsistensi solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $lightGrayAsistensi !important;
	}

	&:focus {
		border: 2px $lightGrayAsistensi solid;
		outline: transparent none 0 !important;
	}
}

.telInputErrorFull {
	width: 100% !important;
	.flag-dropdown {
		@extend .telInput-flag;
		.selected-flag {
			@extend .telInput-selectFlag;
			border-right: 2px $redError solid;
			&:focus {
				border: 2px $redError solid;
			}
		}
	}

	.form-control {
		@extend .teInput-formControl;
		border-color: $redError !important;
	}

	&:focus {
		border: 2px $redError solid;
		outline: transparent none 0 !important;
	}
}
